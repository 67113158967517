// extracted by mini-css-extract-plugin
export var badge = "blog_preview-module--badge--fc8cb";
export var badgeBorder = "blog_preview-module--badgeBorder--cf311";
export var badgeContainer = "blog_preview-module--badgeContainer--e6625";
export var borderDiv = "blog_preview-module--borderDiv--2debd";
export var containerMobile = "blog_preview-module--containerMobile--203ba";
export var containerWeb = "blog_preview-module--containerWeb--c3cd7";
export var content = "blog_preview-module--content--57e78";
export var divImage = "blog_preview-module--divImage--b306c";
export var featureContent = "blog_preview-module--featureContent--a7e29";
export var featureImg = "blog_preview-module--featureImg--1eddd";
export var featureImgLeft = "blog_preview-module--featureImgLeft--63410";
export var featureImgRight = "blog_preview-module--featureImgRight--876e5";
export var gridContainerLeft = "blog_preview-module--gridContainerLeft--b205a";
export var gridContainerMid = "blog_preview-module--gridContainerMid--e36f3";
export var gridContainerRight = "blog_preview-module--gridContainerRight--5c0df";
export var icon = "blog_preview-module--icon--2353d";
export var icon2 = "blog_preview-module--icon2--c0582";
export var iconText = "blog_preview-module--iconText--1b98a";
export var rightContent = "blog_preview-module--rightContent--2a6c1";
export var title = "blog_preview-module--title--ead5f";
export var topInformations = "blog_preview-module--topInformations--7c0c1";