// extracted by mini-css-extract-plugin
export var badge = "blog_featured-module--badge--c41bd";
export var badgeBorder = "blog_featured-module--badgeBorder--920f3";
export var badgeContainer = "blog_featured-module--badgeContainer--70d9d";
export var container = "blog_featured-module--container--7f067";
export var containerMobile = "blog_featured-module--containerMobile--71952";
export var containerWeb = "blog_featured-module--containerWeb--b5e7a";
export var content = "blog_featured-module--content--2c07b";
export var divImage = "blog_featured-module--divImage--ca28e";
export var featureContent = "blog_featured-module--featureContent--f7829";
export var featureImg = "blog_featured-module--featureImg--5fc52";
export var featureImgLeft = "blog_featured-module--featureImgLeft--a42ae";
export var featureImgRight = "blog_featured-module--featureImgRight--0c073";
export var icon = "blog_featured-module--icon--e4f24";
export var icon2 = "blog_featured-module--icon2--89e91";
export var iconText = "blog_featured-module--iconText--b895e";
export var rightContent = "blog_featured-module--rightContent--a8d06";
export var title = "blog_featured-module--title--49944";
export var topInformations = "blog_featured-module--topInformations--533ff";