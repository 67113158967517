import React from 'react'
import Typography from '../ui/typography'
import Container from '../ui/container'
import * as styles from './blog_index.module.css'
import * as stylesHero from './blog_hero.module.css'
import * as stylesFeatured from './blog_featured.module.css'
import * as stylesSection from './blog_section.module.css'
import * as stylesPreview from './blog_preview.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'
import calendar from '../../../static/assets/img/calendar.svg'
import clock from '../../../static/assets/img/clock.svg'
import AppLinks from '../app_links/app_links'

const { SubTitle, Paragraph } = Typography

export const BlogHero = (props) => (
  <Container className={styles.heroContainer}>
    <div className={stylesHero.heroTextContainer}>
      <Paragraph className={stylesHero.tag}>
        {/* Could be a Contentful data {props.topBubbleText} */}
        Blog Post
      </Paragraph>
      <h1 className={stylesHero.title}>{props.title}</h1>
      <Paragraph className={stylesHero.subtitle}>{props.tagline}</Paragraph>
      {props.assets && props.showAppLinks && (
        <div>
          <AppLinks assets={props.assets} browserLink={true} isBlack={false} />
        </div>
      )}
    </div>
  </Container>
)

// the top blog article that appears under Hero with big image and description
export const FeaturedBlog = (props) => (
  <Container className={stylesFeatured.container}>
    <div className={stylesFeatured.featureImgLeft}>
      <a
        href={'/blog/' + props.blogItem.slug + '/'}
        className={stylesFeatured.divImage}
      >
        <GatsbyImage
          alt={props.blogItem.heroImage.title}
          className={stylesFeatured.featureImg}
          imgStyle={{
            objectFit: 'contain',
            objectPosition: 'center',
            borderRadius: '24px',
            overflow: 'hidden',
          }}
          image={props.blogItem.heroImage.gatsbyImageData}
        />
      </a>
      <div className={stylesFeatured.rightContent}>
        <div className={stylesFeatured.topInformations}>
          <img src={calendar} className={stylesFeatured.icon} alt="calendar" />
          <p className={stylesFeatured.iconText}>
            {props.blogItem.publishDate.substring(0, 10)}
          </p>
          <img src={clock} className={stylesFeatured.icon2} alt="clock" />
          <p className={stylesFeatured.iconText}>
            {estimateReadingTime(props.blogItem.numberOfWords)}
          </p>
        </div>
        <a
          href={'/blog/' + props.blogItem.slug + '/'}
          className={stylesFeatured.featureContent}
        >
          <SubTitle className={stylesFeatured.title}>
            {props.blogItem.title}
          </SubTitle>
          <Paragraph className={stylesFeatured.content}>
            {props.blogItem.description.description}
          </Paragraph>
        </a>
      </div>
    </div>
  </Container>
)

// the section that appears under Featured blog with grid of articles on Desktop
export const BlogSection = (props) => (
  <div>
    {props.blogItems && (
      <Container className={stylesSection.container}>
        <div className={stylesSection.featureContent}>
          <SubTitle className={stylesSection.title}>{props.title}</SubTitle>

          {convertToSubGrid(props.blogItems).map((item, index) => (
            <ul className={stylesSection.grid} key={index}>
              {item.map((item2, index2) => (
                <BlogPreview key={index2} blogItem={item2} ind={index2} />
              ))}
            </ul>
          ))}
          {}
        </div>
      </Container>
    )}
  </div>
)

// Blog preview in a section, only image and title (+ reading time and date)
export const BlogPreview = (props) => (
  // Here gap doesn't work to split cells, because if there is only one cell
  // on Desktop flex row then gap will not be effective the width of the cell
  // of the row will be wider, we need to look at index and adapt padding in 3 sections, left, mid, right
  <div
    className={
      props.ind % BLOG_NUM_COLUMNS === 0
        ? stylesPreview.gridContainerLeft
        : props.ind % BLOG_NUM_COLUMNS === BLOG_NUM_COLUMNS - 1
        ? stylesPreview.gridContainerRight
        : stylesPreview.gridContainerMid
    }
  >
    <div className={stylesPreview.featureImgLeft}>
      <a
        href={'/blog/' + props.blogItem.slug + '/'}
        className={stylesPreview.divImage}
      >
        <GatsbyImage
          alt={props.blogItem.heroImage.title}
          className={stylesPreview.featureImg}
          imgStyle={{
            objectFit: 'contain',
            objectPosition: 'center',
          }}
          image={props.blogItem.heroImage.gatsbyImageData}
        />
      </a>
    </div>
    <div className={stylesPreview.rightContent}>
      <div className={stylesPreview.topInformations}>
        <img src={calendar} className={stylesPreview.icon} alt="calendar" />
        <p className={stylesPreview.iconText}>
          {props.blogItem.publishDate.substring(0, 10)}
        </p>
        <img src={clock} className={stylesPreview.icon2} alt="clock" />
        <p className={stylesPreview.iconText}>
          {estimateReadingTime(props.blogItem.numberOfWords)}
        </p>
      </div>
      <a
        href={'/blog/' + props.blogItem.slug + '/'}
        className={stylesPreview.featureContent}
      >
        <SubTitle className={stylesPreview.title}>
          {props.blogItem.title}
        </SubTitle>
      </a>
    </div>
    <div className={stylesPreview.borderDiv} />
  </div>
)

const BLOG_NUM_COLUMNS = 3

// Convert array of blog items into array of arrays,
// where subarrays are of length BLOG_NUM_COLUMNS
// e.g. [1,2,3,4,5] => [[1,2], [3,4], [5]] if BLOG_NUM_COLUMNS = 2
function convertToSubGrid(listBlogItems) {
  const newList = []
  // Prevents Gastby build error
  if (listBlogItems) {
    for (let i = 0; i < listBlogItems.length / BLOG_NUM_COLUMNS; i++) {
      newList.push(
        listBlogItems.slice(i * BLOG_NUM_COLUMNS, (i + 1) * BLOG_NUM_COLUMNS)
      )
    }
  }
  return newList
}

function estimateReadingTime(wordCount) {
  const wordsPerMinute = 200 // calibrated to match Medium's estimation
  const minutesToRead = Math.max(
    Math.round(wordCount / wordsPerMinute),
    1 //ensures we don't get 0 minutes to read
  )
  return `${minutesToRead} min read`
}
