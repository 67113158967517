import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/website_layout/layout'
import { assetNames } from '../utilities.js/constants'
import {
  FeaturedBlog,
  BlogSection,
  BlogHero,
} from '../components/blog_index/blog_index'

import './base.css'
// Prevent Gatsby SSR build errors
const isBrowser = typeof window !== 'undefined'

class Blog extends React.Component {
  componentDidMount() {
    if (isBrowser) {
      // Track seeing this page
      window.analytics.track('website_page_viewed', {
        page: 'blog',
      })
    }
  }

  render() {
    // const siteMetadata = get(this, 'props.data.site.siteMetadata.employee')
    // const websiteData = get(this, 'props.data.contentfulWebsite')

    const assets = assetNames.reduce((res, prop) => {
      res[prop] = get(this, `props.data.${prop}.gatsbyImageData`)
      return res
    }, {})

    const pageContent = get(this, 'props.data.contentfulWageAdvancePageContent')

    // 1) Need Hero Section with blogTitleText and blogTagline
    // 2) Need Featured article section
    // 3) Need to iterate over blogIndexSections (in order)
    //    3.b) Need Blog Section (collate all blogs with the relevant sectionLabel)
    //    3.c) Need Blog Mini Preview Section
    //
    // Blog Index
    const blogIndex = get(this, 'props.data.contentfulBlogIndex')
    // Group the blogs by sectionLabel
    const allposts = get(this, 'props.data.allContentfulBlogPostV4Website')
    const groupedBlogs = allposts.edges.reduce(function (acc, item) {
      acc[item.node.sectionLabel] = acc[item.node.sectionLabel] || []
      acc[item.node.sectionLabel].push(item.node)
      return acc
    }, {})

    // SEO
    const contentfulSiteMetadata = get(
      this,
      'props.data.contentfulSiteMetadata'
    )

    // Override for page meta and title
    contentfulSiteMetadata.title = blogIndex.title
    contentfulSiteMetadata.description = blogIndex.description

    return (
      <>
        <Layout data={pageContent} seo={contentfulSiteMetadata} assets={assets}>
          <BlogHero
            title={blogIndex.blogTitleText}
            tagline={blogIndex.blogTagline}
            assets={assets}
            showAppLinks={true}
          />
          <FeaturedBlog blogItem={blogIndex.featuredPost} />
          {blogIndex.blogIndexSections.map((section, index) => (
            <BlogSection
              key={index}
              title={section.sectionTitle}
              blogItems={groupedBlogs[section.sectionBlogLabel]}
            />
          ))}
        </Layout>
      </>
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query BlogIndex {
    contentfulBlogIndex(contentful_id: { eq: "5Xkk3zZu0fX9AKKMkPOo0O" }) {
      title
      description
      blogTagline
      blogTitleText
      featuredPost {
        title
        description {
          description
        }
        heroImage {
          gatsbyImageData(width: 525)
          title
        }
        publishDate
        slug
        numberOfWords
      }
      blogIndexSections {
        sectionTitle
        sectionBlogLabel
      }
    }
    allContentfulBlogPostV4Website(sort: { publishDate: DESC }) {
      edges {
        node {
          heroImage {
            gatsbyImageData(width: 525)
            title
          }
          description {
            description
          }
          publishDate
          title
          slug
          sectionLabel
          numberOfWords
        }
      }
    }
    contentfulWageAdvancePageContent(
      contentful_id: { eq: "6msPFa337Jh4VoxIaP68Sk" }
    ) {
      topNavigationBar {
        navPaths {
          ... on ContentfulDropDown {
            internal {
              type
            }
            name
            linkEntries {
              label
              url
            }
          }
          ... on ContentfulPath {
            internal {
              type
            }
            url
            label
          }
        }
        logolarge {
          file {
            url
          }
        }
      }
      ctaSectionContent {
        successMessage
        errorMessage
        subtitle
        title
        placeholderTitle
        placeholderFieldName
        ctaTitle
        image {
          gatsbyImageData(width: 396)
          title
        }
      }
      footerContent {
        section1Title
        section2Title
        section3Title
        title
        section1Paths {
          newTab
          label
          url
          logo {
            gatsbyImageData(width: 80)
            title
          }
        }
        section2Paths {
          label
          url
          logo {
            gatsbyImageData(width: 80)
            title
          }
        }

        section3Paths {
          label
          url
          logo {
            gatsbyImageData(width: 80)
            title
          }
        }
      }
    }
    googlePlayBadgeBlack: contentfulCtaButton(
      contentful_id: { eq: "q00MfpdmihX36ZSSzO2VS" }
    ) {
      buttonAlt
      buttonImage {
        gatsbyImageData(width: 235)
      }
    }
    appStoreBadgeBlack: contentfulCtaButton(
      contentful_id: { eq: "4JX8m4WjDpVydFskT6GEeu" }
    ) {
      buttonAlt
      buttonImage {
        gatsbyImageData(width: 235)
      }
    }
    googlePlayBadge: contentfulAsset(
      file: { fileName: { eq: "google-store-badge-new.png" } }
    ) {
      gatsbyImageData(width: 235)
    }
    appStoreBadge: contentfulAsset(
      file: { fileName: { eq: "app-store-badge-new.png" } }
    ) {
      gatsbyImageData(width: 235)
    }
    browserBadge: contentfulAsset(
      file: { fileName: { eq: "browser-badge.png" } }
    ) {
      gatsbyImageData(width: 235)
    }
    contentfulSiteMetadata(contentful_id: { eq: "4GzU8oQlPc2YDh3dwyPU1N" }) {
      description
      imageUrl
      siteName
      title
      twitterHandle
      siteUrl
    }
  }
`
